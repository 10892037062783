import React, {useCallback, useState} from "react";
import {Modal} from "@mui/material";
import styles from "./modalLayout.module.css"
import cn from "classnames";
import {FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FormData} from "../../utils/PropsTypes";
import {phoneRegExp} from "../../utils/InitialParams";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";
import * as yup from "yup";

type ModalLayout = {
    children: React.ReactNode,
}

const modalState = {
    showModal: false,
    openFormModal() {
        this.showModal = true
    }
}

export const ModalContext = React.createContext(modalState);

const ModalLayout: React.FC<ModalLayout> = (
    {
        children
    }) => {
    const [showModal, setShowModal] = useState(false);
    const [successSendModal, openSuccessSendModal] = useState(false);
    const router = useRouter();
    const t: any = router.locale === 'en' ? en : ru;

    const schema = yup
        .object({
            name: yup.string().required(t.schema.required),
            email: yup
                .string()
                .email(t.schema.email)
                .required(t.schema.required),
            phone: yup
                .string()
                .required(t.schema.required)
                .matches(
                    phoneRegExp,
                    t.schema.phone
                ),
        })
        .required();

    const openFormModal = useCallback(() => {
        setShowModal(true)
    }, [])

    const [loadingForm, setLoadingForm] = useState(false)
    const {register, handleSubmit, formState: {errors}, setError, reset} = useForm<FormData>({
        resolver: yupResolver(schema)
    });

    const closeAllModals = useCallback(() => {
        reset();
        setShowModal(false);
        openSuccessSendModal(false);
    }, [])

    const submitHandler: SubmitHandler<FieldValues> = async (data) => {
        setLoadingForm(true)

        try {
            await fetch('/api/contact', {
                method: 'post',
                body: JSON.stringify(data),
            });

            openSuccessSendModal(true)
        } catch (e) {
            console.log('error', e);
            setError("message", {
                message: t.form.error,
            })
        }

        setLoadingForm(false)
    }

    return (
        <>
            <ModalContext.Provider value={{showModal: showModal, openFormModal: openFormModal}}>
                {children}
                <Modal
                    open={showModal}
                    onClose={closeAllModals}
                    aria-labelledby={t.btn.send}
                    aria-describedby={t.form.requiredAll}
                    className={styles.container}
                >
                    {!successSendModal ? (
                        <div className={styles.formContainer}>
                            <a
                                className={styles.close}
                                onClick={closeAllModals}
                                aria-describedby={'close modal'}
                            />

                            <h1 className={cn('services__title', styles.formTitle)}>
                                {t.form.title}
                            </h1>
                            <p className={cn('body-text_gray', styles.formDescription)}>
                                {t.form.text}
                            </p>

                            <form onSubmit={handleSubmit(submitHandler)}>
                                <div className={cn(styles.formContactsInputContainer, styles.formInputs)}>
                                    <div className={cn('text-input-container', styles.textInputContainer)}>

                                        <div>
                                            <input
                                                {...register("name")}
                                                className={cn('text-input', {
                                                    'text-input-error': errors.name
                                                })}
                                                type={'text'}
                                                name={'name'}
                                                placeholder={t.form.placeholder.name}
                                            />
                                            {errors.name && <p className={'error-text'}>
                                                {t.form.required}
                                            </p>}
                                        </div>

                                        <div>
                                            <input
                                                {...register("email")}
                                                className={cn('text-input', {
                                                    'text-input-error': errors.email
                                                })}
                                                type={'text'}
                                                name={'email'}
                                                placeholder={'E-mail*'}
                                            />
                                            {errors.email && <p className={'error-text'}>
                                                {t.form.required}                                        </p>}
                                        </div>


                                        <div>
                                            <input
                                                {...register("phone")}
                                                className={cn('text-input', {
                                                    'text-input-error': errors.phone
                                                })}
                                                type={'text'}
                                                name={'phone'}
                                                placeholder={t.form.placeholder.phone}
                                            />
                                            {errors.phone && <p className={'error-text'}>
                                                {t.form.required}                                        </p>}
                                        </div>


                                    </div>
                                    <div className={styles.descBlock}>
                                      <textarea
                                          {...register("message")}
                                          className={'textarea'}
                                          name={'message'}
                                          placeholder={t.form.placeholder.message}
                                      />

                                        {errors.message && <p className={'error-text'}>
                                            {t.form.required}
                                        </p>}
                                    </div>
                                </div>

                                <p className={cn(
                                    'description-form-text description-form-text-wrapper',
                                    styles.descriptionFormTextWrapper
                                )}>
                                    {t.form.description}
                                </p>

                                <button className={'button_base'} disabled={loadingForm}>
                                    {t.btn.send}
                                </button>
                            </form>
                        </div>
                    ) : (
                        <div className={cn(styles.formContainer, styles.successFormContainer)}>
                            <a
                                className={styles.close}
                                onClick={closeAllModals}
                                aria-describedby={'close modal'}
                            />

                            <h1 className={cn('services__title', styles.formTitle, 'text-center', styles.successFormTitle)}>
                                {t.form.contactUs}
                            </h1>
                            <p className={cn('body-text_gray', 'text-center', styles.formDescription, styles.formSuccessDescription)}>
                                {t.form.weWillCall}
                            </p>

                            <button className={'button_base block-center'} onClick={closeAllModals}>
                                {t.btn.close}
                            </button>
                        </div>
                    )}
                </Modal>
            </ModalContext.Provider>
        </>
    )
}

export default ModalLayout;